@import "../../../styles/common/colors";

.button {
  padding: 4px 20px;
  border-radius: 8px;
  cursor: pointer;

  &:disabled {
    opacity: 0.6;
    cursor: default;
    pointer-events: none;
  }
}
.primary {
  padding: 5px 20px;
  @apply text-brand-secondaryContrast bg-brand-secondary;
}
.secondary {
  color: $primary3;
  background-color: $neutrals9;
  border: 1px solid $neutrals6;
  @include dark {
    background-color: $neutrals1;
    color: $neutrals5;
  }
}
.danger {
  color: $neutrals9;
  background-color: $redLight;
}
.green {
  color: $neutrals9;
  background-color: $greenLight;
}
.orange {
  color: $neutrals9;
  padding: 5px 20px;
  background-color: $orangeLight;
}
.greenLight {
  color: $green;
  background-color: rgba($color: $green, $alpha: 0.2);
}
.blueLight {
  color: $primary2;
  background-color: rgba($color: $primary2, $alpha: 0.2);
}
.redLight {
  color: $accent1;
  background-color: rgba($color: $accent1, $alpha: 0.2);
}
.accent1 {
  color: $neutrals9;
  padding: 5px 20px;
  background-image: linear-gradient(
    to right,
    $accent1 0%,
    #e78668 51%,
    $accent1 100%
  );
  transition: 0.5s;
  background-size: 200% auto;

  &:hover {
    background-position: right center;
  }
}

.border {
  color: $text2;
  padding: 8px 10px;
  border: 2px solid $text4;
  transition: all ease 0.2s;
  @include dark {
    border: 2px solid $neutrals5;
    color: $neutrals5;
  }
  &:hover {
    color: $neutrals9;
    background-color: $blue;
    opacity: 0.8;
  }
}

.borderDark {
  color: $text2;
  padding: 8px 10px;
  border: 1px solid $neutrals6;
  transition: all ease 0.2s;
  @include dark {
    color: $neutrals5;
  }
}

.responsiveButton {
  padding: 4px 20px;
  border-radius: 6px;
  cursor: pointer;

  &:disabled {
    opacity: 0.6;
    cursor: default;
    pointer-events: none;
  }

  @media (max-width: 768px) {
    padding: 2px 10px;
  }
}

.blueBorder {
  background: transparent;
  border: 2px dashed $blue;
  color: $blue;
}
.borderGreen {
  background: transparent;
  border: 2px solid $greenLight;
  padding: 8px 10px;
  color: $greenLight;
}
.borderRed {
  background: transparent;
  border: 2px solid $redLight;
  padding: 8px 10px;
  color: $redLight;
}
.borderOrange {
  background: transparent;
  border: 2px solid $orangeLight;
  padding: 8px 10px;
  color: $orangeLight;
}
.borderBlue {
  background: transparent;
  border: 2px solid $primary2;
  padding: 8px 10px;
}
